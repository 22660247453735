import request from '../utils/request'
import baseUrl from './baseUrl'

export const columnList = (data) => request({
  url: baseUrl + '/column/findColumn',
  method: 'POST',
  data
})
export const addColumn = (data) => request({
  url: baseUrl + '/column/addColumn',
  method: 'POST',
  data
})
export const editColumn = (data) => request({
  url: baseUrl + '/column/updateColumn',
  method: 'POST',
  data
})
export const editSort = (data) => request({
  url: baseUrl + '/column/modifyDept',
  method: 'POST',
  data
})
export const findColumnListByUser = (data) => request({
  url: baseUrl + '/column/findColumnListByUser',
  method: 'POST'
})
export const delColum = (data) => request({
  url: baseUrl + '/column/deleteColumn',
  method: 'POST',
  data
})