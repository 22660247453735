import request from '../utils/request'
import baseUrl from './baseUrl'

const filterUrl = (type) => {
  let url = ''
  switch (type) {
    case 1:
      url = '/order/selectHotelOrder'
      break
    case 2:
      url = '/order/selectTicketOrder'
      break
    case 3:
      url = '/order/selectTravelOrder'
      break
    case 4:
      url = '/order/selectCardOrder'
      break
    case 5:
      url = '/order/selectGroupOrder'
      break
    case 6:
      url = '/order/selectSeckillOrder'
      break
    case 7:
      url = '/order/selectFoodOrder'
      break
    case 8:
      url = '/order/selectPresaleOrder'
      break
      case 9:
      url = '/order/selectPackOrder'
      break

  }
  return url
}
export const orderList = (data) => request({
  url: baseUrl + filterUrl(data.type),
  method: 'POST',
  data,
})
export const hotelOrderDetail = (data) => request({
  url: baseUrl + '/order/selectHotelOrderInfo',
  method: 'POST',
  data
})
export const groupOrderDetail = (data) => request({
  url: baseUrl + '/order/selectGoupOrderInfo',
  method: 'POST',
  data
})
export const orderAddRemark = (data) => request({
  url: baseUrl + '/order/addRemark',
  method: 'POST',
  data
})

export const confirmOrder = (data) => request({
  url: baseUrl + '/order/confirmOrder',
  method: 'POST',
  data
})
//退款订单
export const refundOrder = (data) => request({
  url: baseUrl + '/order/selectForRefund',
  method: 'POST',
  data
})
//退款订单详情 
export const refundOrderdetail = (data) => request({
  url: baseUrl + '/order/selectForCoding',
  method: 'POST',
  data
})
//确认游玩 
export const sureComing = (data) => request({
  url: baseUrl + '/order/modifySureComing',
  method: 'POST',
  data
})
//取消订单 
export const cancelOrder = (data) => request({
  url: baseUrl + '/order/modifyCancel',
  method: 'POST',
  data
})
//通过申请 
export const refundOk = (data) => request({
  url: baseUrl + '/order/modifyRefund',
  method: 'POST',
  data
})
//拒绝退款 
export const refundCancel = (data) => request({
  url: baseUrl + '/order/modifyRefundCancel',
  method: 'POST',
  data
})
//确认成团
export const confirmGroup = (data) => request({
  url: baseUrl + '/order/confirmGroup',
  method: 'POST',
  data
})
//生成报表
export const createForm = (data) => request({
  url: baseUrl + '/order/createForm',
  method: 'POST',
  data,
  responseType: "blob",
})
export const createFormAll = (data) => request({
  url: baseUrl + '/order/createFormAll',
  method: 'POST',
  data,
  responseType: "blob",
})
//退款报表 
export const createRefundForm = (data) => request({
  url: baseUrl + '/order/createRefundForm',
  method: 'POST',
  data,
  responseType: "blob"
})
//订单消息  
export const selectNoReview = (data) => request({
  url: baseUrl + '/order/selectNoReview',
  method: 'POST',
  data,
})

// 订单消息前往详情
export const selectNoSure = (data) => request({
  url: baseUrl + '/order/selectNoSure',
  method: 'POST',
  data,
})
// 查询订单支付信息
export const checkBuyInfo = (data) => request({
  url: baseUrl + '/order/selectPayInfo',
  method: 'POST',
  data,
})
// 强制退款
export const mandatoryRefundData = (data) => request({
  url: baseUrl + '/order/modifyForceRefund',
  method: 'POST',
  data,
})
// 设置分销比例
export const setModData = (data) => request({
  url: baseUrl + '/order/modifyProfit',
  method: 'POST',
  data,
})
// 退款（新）
export const newBack = (data) => request({
  url: baseUrl + '/order/modifyRefundV',
  method: 'POST',
  data,
})
// 修改订单状态
export const orderStatus = (data) => request({
  url: baseUrl + '/order/modify',
  method: 'POST',
  data,
})
// 修改出行时间
export const orderTime = (data) => request({
  url: baseUrl + '/order/modifyBeginTime',
  method: 'POST',
  data,
})
// 上传图片
export const uoLoadImgs = (data) => request({
  url: baseUrl + '/order/modifyImg',
  method: 'POST',
  data,
})

// 活动页下单详情

export const selectForBack = (data) => request({
  url: baseUrl + '/order/selectForBack',
  method: 'POST',
  data,
})
// 活动页下单详情

export const orderListAll = (data) => request({
  url: baseUrl + '/order/selectAll',
  method: 'POST',
  data,
})