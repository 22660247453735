<template>
  <div class="index">
    <el-menu
      :default-active="activePath"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      background-color="#545c64"
      text-color="#fff"
      router
      mode="vertical"
      unique-opened
      collapse-transition
      :default-openeds="defaultOpen"
      @open="onOpen"
    >
      <el-menu-item class="top" router @click="$router.push('/home')">
        <i class="el-icon-s-home"></i>
        <span slot="title" v-if="!isCollapse">首页</span>
      </el-menu-item>
      <el-menu-item class="top" router @click="$router.push('/dataView')">
        <i class="el-icon-s-home"></i>
        <span slot="title" v-if="!isCollapse">万小店每日看板</span>
      </el-menu-item>
      <el-submenu
        v-for="item in manageColumnList.manageColumnList"
        :key="item.columnId"
        :index="item.columnId + ''"
      >
        <template slot="title">
          <i class="iconfont" :class="item.columnIcon"></i>
          <span>{{ item.columnName }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            :to="item.columnRouteUrl"
            v-for="item in item.manageColumnList"
            :key="item.columnId"
            :index="item.columnRouteUrl"
            @click="saveNavState(item.columnRouteUrl)"
          >
            <span>{{ item.columnName }}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
import { columnList } from "../../api/column";
export default {
  name: "NavbarIndex",
  data() {
    return {
      activePath: "",
      allColumnList: [],
      columnAList: [], // 二级
      ibj: [],
    };
  },
  props: {
    isCollapse: {
      type: Boolean,
      required: true,
    },
    manageColumnList: {
      type: [Object, Array],
      required: true,
    },
    defaultOpen: {
      type: Array,
    },
  },
  watch: {
    


    id() {
      this.getColumnList();
    },
  },
  computed: {
    // CalssBList() {
    //   const obj = this.manageColumnList.manageColumnList;
    //   const list = obj.map((item) => {
    //     item.index = item.columnId + "";
    //   });
    //   console.log(list, "weqweqweqwe");
    //   return list;
    // },
  },
  created() {
    this.getColumnList();
    this.activePath = window.sessionStorage.getItem("activePath");
    console.log("执行了created");
  },
  updated(){
   this.activePath = window.sessionStorage.getItem("activePath");

  },
  methods: {
    onOpen(e, o) {
      console.log(e, o, "e");
    },
    saveNavState(path) {
      
      window.sessionStorage.setItem("activePath", path);
      this.activePath = path;
    },
    thirdColumn(item) {
      const obj = this.allColumnList.filter((i) => {
        return i.columnParentId === item.columnId && i.columnTier === 3;
      });
      return obj;
    },
    onAgree() {
      this.$router.push("/agreement");
    },
    async getColumnList() {
      console.log(this.manageColumnList);
      const { data } = await columnList({
        currentPage: 1,
        pageSize: 1000,
        parentId: this.id,
        roleId: "",
      });
      this.allColumnList = data.list;
      const list = data.list.filter((item) => {
        return item.columnParentId === this.id;
      });
      list.map((item) => {
        item.index = item.columnId + "";
      });
      this.columnAList = list;
      console.log(this.columnAList, "columnAList");
    },
  },
};
</script>
<style lang="less" scoped>
.index {
  height: 100%;
  background-color: rgb(84, 92, 100);
  .el-menu {
    border: 1px;
    .el-submenu {
      .el-submenu__title {
        i {
          margin-right: 8px;
        }
      }

    }
    .top {
      color: #fff;
      i {
        
        color: #fff;
      }
    }
  }
}
</style>